import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import Garden from "../../content/generated/launchpad.mdx"
import SEO from "../components/SEO"

const GardenPage = () => {
  return (
    <Layout>
      <SEO title="Digital Garden" />
      <Wrapper>
        <div className="container">
          <h1>Garden</h1>
          <Garden />
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }

    li {
      margin-bottom: 0.4em;
    }
  }

  @media screen and (max-width: 500px) {
    .container {
      padding: 0 1em;
      width: 100vw;
    }
  }
`

export default GardenPage
