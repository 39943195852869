import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "type": "GARDEN_NOTE",
  "title": "Launchpad",
  "last_updated_on": "2020-10-27T00:00:00.000Z",
  "slug": "launchpad",
  "status": "SEEDLING",
  "draft": false
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This is a regularly tended collection of my research, notes, and thoughts, in a format popularly known as `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Zettelkasten"
      }}>{`Zettelkasten`}</a>{` (or the `}<strong parentName="p">{`Slip box`}</strong>{`). Specifically, I follow the practices outlined in `}<a parentName="p" {...{
        "href": "https://notes.andymatuschak.org/z4SDCZQeRo4xFEQ8H4qrSqd68ucpgE6LU155C"
      }}>{`Andy Matuschak's Evergreen notes`}</a>{`.`}</p>
    <p>{`I use `}<a parentName="p" {...{
        "href": "https://roamresearch.com/"
      }}>{`Roam Research`}</a>{` to maintain my Zettelkasten. This garden is a direct export from my Roam database, with a few minor transformations to fit the blog format.`}</p>
    <p>{`The format can appear kind of strange on the outset, so some explanation is necessary. The notes in this Slip box are my thinking medium, and mostly written for myself. They form my second brain and help me better understand whatever I learn. They are published here as a way of `}<a parentName="p" {...{
        "href": "https://www.amazon.ca/dp/B0087K3VTW"
      }}>{`working with the garage door up`}</a>{`. `}</p>
    <p><strong parentName="p">{`Topics`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/domain-driven-design"
        }}>{`Domain-Driven Design`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      